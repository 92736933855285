.btn-style
  border: none
  border-radius: 25px
  background-color: $orange-color
  color: #fff
  text-transform: uppercase
  text-align: center
  display: block
  width: 100%
  font-weight: 700
  padding: 11px 0
  transition: background-color .3s
  &:hover
    background-color: $orange-lighter
  &.blue-style
    color: #fff
    background-color: $blue-color
    &:hover
      background-color: $orange-color

.section-title
  font-weight: 500
  @include adaptive-value('font-size', 48, 32, 1)
  @include adaptive-value('line-height', 50, 32, 1)
.breadcrumbs-list
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: flex-end
  font-size: 14px
  padding: 0 27px !important
  color: #414042
  @media (min-width: 1200px)
    font-size: 16px
  @media (max-width: 767px)
    padding: 0 !important
  &__item
    flex: 0 0 auto
    position: relative
    &:after
      content: '/'
      display: inline-block
      margin: 0 4px 0 0px
    &:last-child
      &:after
        display: none
  &__link
    display: inline-block
.title-row
  display: flex
  flex-direction: column-reverse
  padding: 15px 0
  margin-top: 14px
  .page-title
    text-align: left
    margin-top: 34px
    padding: 0 32px
    @media (min-width: 1200px)
      padding: 0
    @media (max-width: 767px)
      padding: 0
.page-title
  font-weight: 500
  @include adaptive-value('font-size', 48, 30, 1)
  @include adaptive-value('line-height', 50, 32, 1)

.check-group
  width: 100%
  flex: 0 0 100%
  max-width: 100% !important
  label
    display: inline-flex
    justify-content: center
    width: 100%
    font-size: 14px
    line-height: 20px
    input
      position: absolute
      top: 0
      left: 0
      width: 0
      height: 0
      visibility: hidden
      &:checked + span:after
        opacity: 1
      &.error-input
        & + span:before
          border-color: red
    span
      display: inline-block
      padding-left: 30px
      color: #fff
      position: relative
      &:before
        content: ''
        display: block
        width: 20px
        height: 20px
        background-color: #fff
        position: absolute
        top: 0
        left: 0
        border: 1px solid transparent
      &:after
        content: ''
        width: 20px
        height: 20px
        position: absolute
        top: 0
        left: 0
        background-image: url(../images/svg/ok.svg)
        background-repeat: no-repeat
        background-position: center
        background-size: 14px
        opacity: 0
        transition: opacity .3s
