.answers-page
  position: relative
  overflow-x: hidden
  @media (min-width: 1024px)
    .circles
      &.circles-top
        &:after
          content: ""
          display: block
          width: 48vw
          height: 48vw
          border-radius: 50%
          background-color: $blue-lighter
          position: absolute
          top: -3.5%
          right: -19vw
          z-index: -1
          @media (min-width: 576px)
            width: 38vw
            height: 38vw
            right: -10vw
            top: 5%
          @media (min-width: 1200px)
            width: 302px
            height: 302px
            top: 5%
            right: 5vw
        &:before
          content: ""
          display: block
          width: 73vw
          height: 73vw
          border-radius: 50%
          position: absolute
          left: -24.5vw
          top: 16.5%
          background-color: $orange-lighter
          z-index: -1
          @media (min-width: 576px)
            width: 50vw
            height: 50vw
            top: 10%
            left: -15%
          @media (min-width: 1200px)
            width: 399px
            height: 399px
            left: 5vw
            top: 10%
      &.circles-center
        &:after
          content: ""
          display: block
          width: 63vw
          height: 63vw
          border-radius: 50%
          background-color: $blue-lighter
          position: absolute
          top: 56%
          right: -11vw
          z-index: -1
          @media (min-width: 576px)
            right: auto
            left: 10%
            width: 55vw
            height: 55vw
            top: 45%
          @media (min-width: 1200px)
            width: 278px
            height: 278px
            left: 15%
            top: 45%
        &:before
          content: ""
          display: block
          width: 39vw
          height: 39vw
          border-radius: 50%
          position: absolute
          left: 10vw
          top: 42.5%
          background-color: $blue-lighter
          z-index: -1
          @media (min-width: 576px)
            display: none
          @media (min-width: 1200px)
            display: block
            background-color: $orange-lighter
            width: 338px
            height: 338px
            left: 55%
            top: 30%
      &.circles-bottom
        &:before
          content: ""
          display: block
          width: 57vw
          height: 57vw
          border-radius: 50%
          position: absolute
          left: -9.5vw
          top: 76.5%
          background-color: $orange-lighter
          z-index: -1
          @media (min-width: 576px)
            display: none
          @media (min-width: 1200px)
            display: block
            width: 431px
            height: 431px
            right: 0
            left: 60%
            top: 55%
            background-color: $blue-lighter
  &__content
    margin-top: 10px
    @media (min-width: 1024px)
      margin-top: 30px
    &__item
      margin-top: 20px
      border: 2px solid $blue-color
      border-radius: 25px
      padding: 20px
      display: flex
      flex-wrap: wrap
      align-items: flex-start
      justify-content: flex-start
      background-color: #fff
      &:nth-of-type(1)
        margin-top: 0
      .date
        font-weight: 600
        flex: 0 0 auto
        width: auto
        margin-left: auto
      .name
        order: -1
        flex: 0 0 auto
        width: auto
        font-size: 18px
        line-height: 20px
      .txt-content
        margin: 15px 0
        font-size: 15px
        line-height: 20px
        width: 100%
        flex: 0 0 100%
      .place
        flex: 0 0 auto
        width: auto
        margin-left:  auto

      @media (min-width: 1024px)
        max-width: 80%
        &:nth-of-type(2n)
          margin-left: auto
  &__form
    margin: 30px auto
    background-color: $blue-color
    border-radius: 25px
    padding: 20px
    .form-title
      color: #fff
      font-weight: 500
      font-size: 24px
      line-height: 24px
      display: block
      margin-bottom: 20px
      @media (min-width: 1024px)
        font-size: 30px
        line-height: 30px
    .input-group
      width: 100%
      margin-top: 15px
      &:nth-of-type(1)
        margin-top: 0
      input, textarea
        width: 100%
        border: 1px solid transparent
        border-radius: 25px
        font-size: 12px
        line-height: 14px
        padding: 12px 20px 14px
        transition: border-color .3s
        &.error-input
          border-color: red
        @media (min-width: 1200px)
          padding: 18px 16px
      button
        padding: 12px 20px
        @media (min-width: 1200px)
          padding: 14px
      textarea
        min-height: 100px
        max-width: 100%
    .answers-form
      @media (min-width: 640px)
        display: flex
        flex-wrap: wrap
        .input-group
          flex: 0 0 calc(50% - 10px)
          max-width: calc(50% - 10px)
          &:nth-of-type(2)
            margin-top: 0
            margin-left: auto
          &:nth-of-type(3)
            flex: 0 0 100%
            max-width: 100%
          &.btn-group
            flex: 0 0 100%
            max-width: 100%
    @media (min-width: 768px)
      display: flex
      flex-wrap: wrap
      .form-left-side
        flex: 0 0 65%
        max-width: 65%
        margin-right: auto
      .form-right-side
        flex: 0 0 30%
        max-width: 30%
        margin-bottom: -20px
        .answers-image
          display: block !important
          margin: 0 auto
