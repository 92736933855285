.services-page
  &__content
    margin: 35px auto 40px
    padding: 0 32px
    @media (min-width: 640px)
      display: flex
      flex-wrap: wrap
    @media (min-width: 1200px)
      padding: 0
    &.four-column
      .services-page__content__item
        @media (min-width: 1200px)
          flex: 0 0 calc(25% - 25px)
          max-width: calc(25% - 25px)
          margin: 0 25px 22px 0
          &:nth-of-type(2n)
            margin-right: 25px
          &:nth-of-type(3n)
            margin-right: 25px
          &:nth-of-type(4n)
            margin-right: 0
    &__item
      margin-bottom: 40px
      @media (min-width: 640px)
        flex: 0 0 calc(50% - 10px)
        max-width: calc(50% - 10px)
        margin: 0 20px 20px 0
        &:nth-of-type(2n)
          margin-right: 0
      @media (min-width: 1200px)
        flex: 0 0 calc(33.3% - 25px)
        max-width: calc(33.3% - 25px)
        margin: 0 38px 22px 0
        &:nth-of-type(2n)
          margin-right: 38px
        &:nth-of-type(3n)
          margin-right: 0
      .image-block
        border-radius: 25px
        overflow: hidden
        .service-link
          display: block
          .service-image
            display: block
            width: 100%
            max-width: 100%
      .descr-block
        text-align: center
        margin: 20px 0
        .service-title
          display: inline-block
          @include adaptive-value('font-size', 21, 18, 1)
          @include adaptive-value('line-height', 25, 22, 1)
    &__btn-row
      text-align: center
      @media (min-width: 640px)
        flex: 0 0 100%
        text-align: center
      @media (min-width: 1200px)
        margin-top: 5px
        margin-bottom: 10px
      .more-services
        display: inline-block
        color: #fff
        width: auto
        padding: 10px 38px
        @media (min-width: 1200px)
          padding: 15px 48px
    &__descr
      flex: 0 0 100%
      width: 100%
      padding: 15px 0 30px
      line-height: 22px
      span
        color: $orange-color
      p
        margin-bottom: 15px
        &:last-child
          margin-bottom: 0
      h3
        margin-top: 40px
        color: $gray-color
        font-weight: 500
        @include adaptive-value('font-size', 21, 18, 1)
        @include adaptive-value('line-height', 25, 22, 1)
        & + p
          margin-top: 20px
      ul,ol
        margin: 18px 0
        padding: 0
        line-height: 19px
        list-style-type: none
        @media (min-width: 1200px)
          margin-left: 10px
        li
          position: relative
          padding-left: 15px
          margin-bottom: 8px
          &:before
            content: ''
            width: 6px
            height: 6px
            border-radius: 50%
            background-color: #396EB0
            position: absolute
            left: 0
            top: 8px
      ol
        list-style-type: decimal
        li
          padding-left: 0
          &:before
            display: none


      a
        display: inline-block
        color: $orange-color
        font-weight: 500