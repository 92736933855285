@-webkit-keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1
.fadeIn
  -webkit-animation-name: fadeIn
  animation-name: fadeIn

@-webkit-keyframes fadeInDown
  from
    opacity: 0
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes fadeInDown
  from
    opacity: 0
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.fadeInDown
  -webkit-animation-name: fadeInDown
  animation-name: fadeInDown

@-webkit-keyframes fadeInLeft
  from
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes fadeInLeft
  from
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.fadeInLeft
  -webkit-animation-name: fadeInLeft
  animation-name: fadeInLeft

@-webkit-keyframes fadeInRight
  from
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes fadeInRight
  from
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.fadeInRight
  -webkit-animation-name: fadeInRight
  animation-name: fadeInRight

@-webkit-keyframes fadeInUp
  from
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes fadeInUp
  from
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

  to
    opacity: 1
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.fadeInUp
  -webkit-animation-name: fadeInUp
  animation-name: fadeInUp

@-webkit-keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0
@keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0
.fadeOut
  -webkit-animation-name: fadeOut
  animation-name: fadeOut

@-webkit-keyframes fadeOutDown
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

@keyframes fadeOutDown
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

.fadeOutDown
  -webkit-animation-name: fadeOutDown
  animation-name: fadeOutDown

@-webkit-keyframes fadeOutLeft
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

@keyframes fadeOutLeft
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

.fadeOutLeft
  -webkit-animation-name: fadeOutLeft
  animation-name: fadeOutLeft

@-webkit-keyframes fadeOutRight
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

@keyframes fadeOutRight
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

.fadeOutRight
  -webkit-animation-name: fadeOutRight
  animation-name: fadeOutRight

@-webkit-keyframes fadeOutUp
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

@keyframes fadeOutUp
  from
    opacity: 1
  to
    opacity: 0
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

.fadeOutUp
  -webkit-animation-name: fadeOutUp
  animation-name: fadeOutUp

@-webkit-keyframes zoomIn
  from
    opacity: 0
    -webkit-transform: scale3d(0.3, 0.3, 0.3)
    transform: scale3d(0.3, 0.3, 0.3)
  50%
    opacity: 1
@keyframes zoomIn
  from
    opacity: 0
    -webkit-transform: scale3d(0.3, 0.3, 0.3)
    transform: scale3d(0.3, 0.3, 0.3)

  50%
    opacity: 1
.zoomIn
  -webkit-animation-name: zoomIn
  animation-name: zoomIn

@-webkit-keyframes zoomInDown
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomInDown
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomInDown
  -webkit-animation-name: zoomInDown
  animation-name: zoomInDown

@-webkit-keyframes zoomInLeft
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomInLeft
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomInLeft
  -webkit-animation-name: zoomInLeft
  animation-name: zoomInLeft

@-webkit-keyframes zoomInRight
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomInRight
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomInRight
  -webkit-animation-name: zoomInRight
  animation-name: zoomInRight

@-webkit-keyframes zoomInUp
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomInUp
  from
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  60%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomInUp
  -webkit-animation-name: zoomInUp
  animation-name: zoomInUp

@-webkit-keyframes zoomOut
  from
    opacity: 1
  50%
    opacity: 0
    -webkit-transform: scale3d(0.3, 0.3, 0.3)
    transform: scale3d(0.3, 0.3, 0.3)

  to
    opacity: 0
@keyframes zoomOut
  from
    opacity: 1
  50%
    opacity: 0
    -webkit-transform: scale3d(0.3, 0.3, 0.3)
    transform: scale3d(0.3, 0.3, 0.3)

  to
    opacity: 0
.zoomOut
  -webkit-animation-name: zoomOut
  animation-name: zoomOut

@-webkit-keyframes zoomOutDown
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  to
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0)
    -webkit-transform-origin: center bottom
    transform-origin: center bottom
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomOutDown
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  to
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0)
    -webkit-transform-origin: center bottom
    transform-origin: center bottom
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomOutDown
  -webkit-animation-name: zoomOutDown
  animation-name: zoomOutDown

@-webkit-keyframes zoomOutLeft
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0)

  to
    opacity: 0
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0)
    transform: scale(0.1) translate3d(-2000px, 0, 0)
    -webkit-transform-origin: left center
    transform-origin: left center

@keyframes zoomOutLeft
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0)

  to
    opacity: 0
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0)
    transform: scale(0.1) translate3d(-2000px, 0, 0)
    -webkit-transform-origin: left center
    transform-origin: left center

.zoomOutLeft
  -webkit-animation-name: zoomOutLeft
  animation-name: zoomOutLeft

@-webkit-keyframes zoomOutRight
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0)

  to
    opacity: 0
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0)
    transform: scale(0.1) translate3d(2000px, 0, 0)
    -webkit-transform-origin: right center
    transform-origin: right center

@keyframes zoomOutRight
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0)

  to
    opacity: 0
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0)
    transform: scale(0.1) translate3d(2000px, 0, 0)
    -webkit-transform-origin: right center
    transform-origin: right center

.zoomOutRight
  -webkit-animation-name: zoomOutRight
  animation-name: zoomOutRight

@-webkit-keyframes zoomOutUp
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  to
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0)
    -webkit-transform-origin: center bottom
    transform-origin: center bottom
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

@keyframes zoomOutUp
  40%
    opacity: 1
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0)
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  to
    opacity: 0
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0)
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0)
    -webkit-transform-origin: center bottom
    transform-origin: center bottom
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1)

.zoomOutUp
  -webkit-animation-name: zoomOutUp
  animation-name: zoomOutUp

@-webkit-keyframes slideInDown
  from
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes slideInDown
  from
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slideInDown
  -webkit-animation-name: slideInDown
  animation-name: slideInDown

@-webkit-keyframes slideInLeft
  from
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes slideInLeft
  from
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slideInLeft
  -webkit-animation-name: slideInLeft
  animation-name: slideInLeft

@-webkit-keyframes slideInRight
  from
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes slideInRight
  from
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slideInRight
  -webkit-animation-name: slideInRight
  animation-name: slideInRight

@-webkit-keyframes slideInUp
  from
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

@keyframes slideInUp
  from
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)
    visibility: visible

  to
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slideInUp
  -webkit-animation-name: slideInUp
  animation-name: slideInUp

@-webkit-keyframes slideOutDown
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

@keyframes slideOutDown
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(0, 100%, 0)
    transform: translate3d(0, 100%, 0)

.slideOutDown
  -webkit-animation-name: slideOutDown
  animation-name: slideOutDown

@-webkit-keyframes slideOutLeft
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

@keyframes slideOutLeft
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(-100%, 0, 0)
    transform: translate3d(-100%, 0, 0)

.slideOutLeft
  -webkit-animation-name: slideOutLeft
  animation-name: slideOutLeft

@-webkit-keyframes slideOutRight
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

@keyframes slideOutRight
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(100%, 0, 0)
    transform: translate3d(100%, 0, 0)

.slideOutRight
  -webkit-animation-name: slideOutRight
  animation-name: slideOutRight

@-webkit-keyframes slideOutUp
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

@keyframes slideOutUp
  from
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

  to
    visibility: hidden
    -webkit-transform: translate3d(0, -100%, 0)
    transform: translate3d(0, -100%, 0)

.slideOutUp
  -webkit-animation-name: slideOutUp
  animation-name: slideOutUp

/* custom */
@-webkit-keyframes krasnoFadeIn
  from
    opacity: 0
    transform: translateY(100%)
  to
    opacity: 1
    transform: translateY(0%)

@keyframes krasnoFadeIn
  from
    opacity: 0
    transform: translateY(100%)
  to
    opacity: 1
    transform: translateY(0%)
.krasnoFadeIn
  -webkit-animation-name: krasnoFadeIn
  animation-name: krasnoFadeIn
.krasnoBgWidthLeft:before
  width: 0px
  overflow: hidden
  transition: width 1s
.krasnoBgWidthLeft.animated:before
  width: calc(100% + 200px)


.animated
  -webkit-animation-duration: 1s
  animation-duration: 1s
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

  &.infinite
    -webkit-animation-iteration-count: infinite
    animation-iteration-count: infinite

  &.delay-1s
    -webkit-animation-delay: 1s
    animation-delay: 1s

  &.delay-2s
    -webkit-animation-delay: 2s
    animation-delay: 2s

  &.delay-3s
    -webkit-animation-delay: 3s
    animation-delay: 3s

  &.delay-4s
    -webkit-animation-delay: 4s
    animation-delay: 4s

  &.delay-5s
    -webkit-animation-delay: 5s
    animation-delay: 5s

  &.fast
    -webkit-animation-duration: 800ms
    animation-duration: 800ms

  &.faster
    -webkit-animation-duration: 500ms
    animation-duration: 500ms

  &.slow
    -webkit-animation-duration: 2s
    animation-duration: 2s

  &.slower
    -webkit-animation-duration: 3s
    animation-duration: 3s



@media (print), (prefers-reduced-motion: reduce)
  .animated
    -webkit-animation-duration: 1ms !important
    animation-duration: 1ms !important
    -webkit-transition-duration: 1ms !important
    transition-duration: 1ms !important
    -webkit-animation-iteration-count: 1 !important
    animation-iteration-count: 1 !important

.my-mfp-zoom-in
  .zoom-anim-dialog
    opacity: 0
    -webkit-transition: all 0.2s ease-in-out
    -moz-transition: all 0.2s ease-in-out
    -o-transition: all 0.2s ease-in-out
    transition: all 0.2s ease-in-out
    -webkit-transform: scale(0.8)
    -moz-transform: scale(0.8)
    -ms-transform: scale(0.8)
    -o-transform: scale(0.8)
    transform: scale(0.8)
  &.mfp-ready .zoom-anim-dialog
    opacity: 1
    -webkit-transform: scale(1)
    -moz-transform: scale(1)
    -ms-transform: scale(1)
    -o-transform: scale(1)
    transform: scale(1)
  &.mfp-removing .zoom-anim-dialog
    -webkit-transform: scale(0.8)
    -moz-transform: scale(0.8)
    -ms-transform: scale(0.8)
    -o-transform: scale(0.8)
    transform: scale(0.8)
    opacity: 0
  &.mfp-bg
    opacity: 0
    -webkit-transition: opacity 0.3s ease-out
    -moz-transition: opacity 0.3s ease-out
    -o-transition: opacity 0.3s ease-out
    transition: opacity 0.3s ease-out
  &.mfp-ready.mfp-bg
    opacity: 0.95
  &.mfp-removing.mfp-bg
    opacity: 0