.footer
  background-color: $blue-color
  margin-top: auto
  &__content
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    position: relative
    padding: 35px 0
    @media (min-width: 414px)
      padding: 40px 32px 45px
    @media (min-width: 576px)
      padding: 20px 30px
      align-items: center
    @media (min-width: 768px)
      padding: 20px 0
    @media (min-width: 1200px)
      padding: 30px 0
    .logo-link
      display: inline-flex
      flex: 0 0 auto
      align-items: flex-end
      margin-bottom: 35px
      @media (min-width: 414px)
        margin-top: 10px
      @media (min-width: 576px)
        margin: 0 30px 0 0
      @media (min-width: 1200px)
        margin: 0 95px 0 0
    .logo-image
      display: block
      max-width: 60px
      flex: 0 0 60px
      @media (min-width: 1200px)
        max-width: 80px
        flex: 0 0 80px
        position: relative
        left: -3px
    .logo-descr
      display: flex
      flex-direction: column
      color: #fff
      margin-left: 10px
      flex: 0 0 auto
      @media (min-width: 1200px)
        margin-left: 12px
      .logo-title
        font-family: Glametrix, sans-serif
        font-weight: 700
        font-size: 26px
        line-height: 26px
        display: inline-block
        width: 100%
        @media (min-width: 1200px)
          font-size: 34px
          line-height: 34px
      .logo-subtitle
        font-size: 12px
        line-height: 14px
        display: inline-block
        max-width: 100px
        @media (min-width: 768px)
          margin-bottom: 5px
        @media (min-width: 1200px)
          max-width: 140px
          font-size: 16px
          line-height: 20px
          margin: 0

    .footer-menu
      @media (min-width: 1200px)
        display: block !important
        margin: 0 auto
      .main-menu
        &__list
          @media (min-width: 1200px)
            display: flex
            align-items: center
        &__item
          @media (min-width: 1200px)
            margin: 0 24px
        &__link
          @media (min-width: 1200px)
            color: #fff
            text-transform: none
            font-size: 18px
            line-height: 21px
    .social-links
      display: flex
      flex-direction: column
      margin-left: auto
      @media (min-width: 576px)
        flex-direction: row
        margin: 0
      @media (min-width: 1200px)
        margin-left: 0
      &__item
        display: inline-block
        margin: 5px 0
        @media (min-width: 414px)
          margin: 0 0 16px 0
        @media (min-width: 576px)
          margin: 0 8px
        @media (min-width: 768px)
          display: inline-flex
          align-items: center
        @media (min-width: 1200px)
          margin: 0 10px
        &:last-child
          margin-bottom: 0
    .phone-block
      position: absolute
      color: #fff
      left: 33px
      top: 120px
      font-weight: 500
      font-size: 18px
      z-index: 10
      @media (min-width: 576px)
        position: relative
        top: 0
        left: 0
        margin-left: auto
      @media (min-width: 768px)
        margin-left: 30px
      @media (min-width: 1200px)
        .phone-descr
          display: block !important
          color: #fff
          font-weight: 500
          font-size: 16px
          line-height: 19px
        .phone-link
          font-size: 24px
          line-height: 28px
          display: inline-block
          margin-bottom: 4px
      .phone-descr
        @media (min-width: 768px)
          display: block !important
          font-size: 16px
          line-height: 20px
          font-weight: 500
          margin-top: 3px
