.service-single
  &__content
    margin-top: 28px
    margin-bottom: 40px
    .service-image
      display: block
      border-radius: 25px
      width: auto
      max-width: calc(100% + 30px)
      margin: 0 -15px
      @media (min-width: 576px)
        max-width: 100%
        margin: 0 auto
    .descr-block
      padding: 35px 32px
      line-height: 19px
      @media (min-width: 1200px)
        padding: 50px 0
      span
        color: $orange-color
      h3
        margin-top: 40px
        color: $gray-color
        font-weight: 500
        @include adaptive-value('font-size', 21, 18, 1)
        @include adaptive-value('line-height', 25, 22, 1)
        & + p
          margin-top: 20px
      ul
        margin: 18px 0
        padding: 0
        line-height: 19px
        list-style-type: none
        @media (min-width: 1200px)
          margin-left: 10px
        li
          position: relative
          padding-left: 15px
          margin-bottom: 8px
          &:before
            content: ''
            width: 6px
            height: 6px
            border-radius: 50%
            background-color: #396EB0
            position: absolute
            left: 0
            top: 8px

      a
        display: inline-block
        color: $orange-color
        font-weight: 500
      p
        margin-bottom: 15px
        &:last-child
          margin-bottom: 0

    &__btn-row
      margin-top: 6px
      text-align: center
      .backcall-link
        display: inline-block
        width: auto
        padding: 15px 48px
        color: #fff
    &.contacts-content
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-wrap: wrap
      .service-single__content__left-side
        flex: 0 0 50%
        max-width: 50%
        @media (max-width: 767px)
          flex: 0 0 100%
          max-width: 100%
        .descr-block
          padding: 0
          h3
            &:nth-of-type(1)
              margin-top: 0
          a
            font-size: 22px
            line-height: 22px
      .service-single__content__right-side
        flex: 0 0 50%
        max-width: 50%
        @media (max-width: 767px)
          flex: 0 0 100%
          max-width: 100%
          margin-top: 35px
        iframe
          background-color: #aaa // tmp
          width: 100%
          min-height: 400px