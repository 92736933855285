.simple-page
  padding: 30px
  h1,h2,h3,h4
    margin: 10px 0
  p
    line-height: 19px
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
  ul
    margin: 20px 0
    padding: 0 0 0 20px