// pseudo element
@mixin pseudo($display: block, $pos: absolute, $content: '')
  content: $content
  display: $display
  position: $pos

// placeholder
@mixin input-placeholder
  &.placeholder
    @content
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
// scrollbars
@mixin scrollbars($size: 5px, $background-color: #000, $foreground-color: #aaa, $hover-color: #666, $border-radius: 0)
  &::-webkit-scrollbar
    width:  $size
    height: $size
    border-radius: $border-radius
  &::-webkit-scrollbar-thumb
    background: $foreground-color
    border-radius: $border-radius
    transition: background-color .3s
    &:hover
      background: $hover-color
  &::-webkit-scrollbar-track
    background: $background-color
    border-radius: $border-radius
  // For Internet Explorer
  &
    scrollbar-face-color: $foreground-color
    scrollbar-track-color: $background-color

// gradient
@mixin gradient($start-color, $end-color, $orientation)
  background: $start-color
  @if $orientation == 'vertical'
    background: -webkit-linear-gradient(top, $start-color, $end-color)
    background: linear-gradient(to bottom, $start-color, $end-color)
  @else if $orientation == 'horizontal'
    background: -webkit-linear-gradient(left, $start-color, $end-color)
    background: linear-gradient(to right, $start-color, $end-color)
  @else
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color)
    background: radial-gradient(ellipse at center, $start-color, $end-color)
// triangle
@mixin arrow($direction: down, $size: 5px, $color: #555)
  width: 0
  height: 0
  @if ($direction == left)
    border-top: $size solid transparent
    border-bottom: $size solid transparent
    border-right: $size solid $color
  @else if ($direction == right)
    border-top: $size solid transparent
    border-bottom: $size solid transparent
    border-left: $size solid $color
  @else if ($direction == down)
    border-left: $size solid transparent
    border-right: $size solid transparent
    border-top: $size solid $color
  @else
    border-left: $size solid transparent
    border-right: $size solid transparent
    border-bottom: $size solid $color
// font
@mixin font-face($font-family, $file-path, $weight, $style)
  @font-face
    font-family: $font-family
    font-weight: $weight
    font-style: $style
    font-display: swap
    src: url('../fonts/#{$file-path}/#{$file-path}.eot')
    src: url('../fonts/#{$file-path}/#{$file-path}.eot?#iefix') format('embedded-opentype'), url('../fonts/#{$file-path}/#{$file-path}.woff') format('woff'), url('../fonts/#{$file-path}/#{$file-path}.woff2') format('woff2'), url('../fonts/#{$file-path}/#{$file-path}.ttf') format('truetype')
// adaptive value
@mixin adaptive-value($property, $startSize, $minSize, $type)
  $addSize: $startSize - $minSize
  @if $type==1
    // < container
    #{$property}: $startSize + px
    @media (max-width: #{$container-width + px})
      #{$property}: calc(#{$minSize + px} + #{$addSize} *((100vw - 320px) / #{$container-width - 320}))
  @else if $type==2
    // > container
    #{$property}: $startSize + px
    @media (min-width: #{$container-width + px})
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$container-width - 320}))
  @else
    // all
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$container-width - 320}))
// vertical align
@mixin valign
  position: relative
  top: 50%
  transform: translateY(-50%)
