.modal-form-style
  max-width: 100%
  background-color: $blue-color
  color: #fff
  max-width: 320px
  margin: 0 auto
  padding: 30px 20px
  border-radius: 25px
  .mfp-close
    color: #fff !important
  .modal-title
    text-align: center
    text-transform: uppercase
    font-weight: 500
    font-size: 18px
    line-height: 21px
  .modal-subtitle
    margin-top: 15px
    font-size: 15px
    line-height: 18px
    text-align: center
    width: 110%
    max-width: 110%
    margin: 15px -5% 0
  .modal-form
    margin-top: 20px
    .input-group
      width: 100%
      margin-top: 20px
      &:nth-of-type(1)
        margin-top: 0
      &.btn-group
        margin-top: 30px
      input
        width: 100%
        border-radius: 25px
        font-weight: 200
        font-size: 12px
        line-height: 14px
        padding: 11px 20px 14px
        border: 1px solid transparent
        transition: border-color .3s
        &.error-input
          border-color: red
  .thanks_content
    text-align: center
    font-size: 16px
    line-height: 19px
    font-weight: 500
